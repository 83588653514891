import { useFormikContext } from 'formik';
import React from 'react';

import { EnhancedClaimsFormProps } from '../../interface/EnhancedClaimsFormProps';

export interface BusinessLogicProps {}

export const BusinessLogic: React.FC<BusinessLogicProps> = () => {
  const { values, setFieldValue, setErrors, setFieldError, errors } =
    useFormikContext<EnhancedClaimsFormProps>();

  const { relationshipToDeceased } = values;
  const { claimantFirstName } = errors;

  const handleBusinessValidation = React.useEffect(() => {
    if (
      relationshipToDeceased === '' ||
      relationshipToDeceased === 'Servicing Representative'
    ) {
      setFieldValue('claimantFirstName', '', true);
      setFieldValue('claimantMiddleInitial', '', false);
      setFieldValue('claimantLastName', '', false);
      setFieldValue('claimantSuffix', '', false);
      setFieldValue('claimantAddress1', '', false);
      setFieldValue('claimantAddress2', '', false);
      setFieldValue('claimantCity', '', false);
      setFieldValue('claimantState', '', false);
      setFieldValue('claimantZipCode', '', false);
      setFieldValue('claimantPhone', '', false);
      setFieldValue('claimantPhoneType', '', false);
      setFieldValue('claimantEmail', '', false);
      setFieldValue('claimantPreferredContactMethod', '', false);
      setFieldValue('relationshipOther', '', false);
    }

    if (
      !relationshipToDeceased ||
      relationshipToDeceased !== 'Servicing Representative'
    ) {
      setFieldValue('servicingRepSalesId', '', false);
      setFieldValue('formsRecipient', '', false);
      setFieldValue('deliverProceeds', '', false);
    }

    if (!relationshipToDeceased || relationshipToDeceased !== 'Other') {
      setFieldValue('relationshipOther', '');
    }
  }, [
    relationshipToDeceased,
    setFieldValue,
    setErrors,
    setFieldError,
    claimantFirstName,
  ]);

  return <>{handleBusinessValidation}</>;
};
