import { ClaimPostRequest } from '../../../../apis';
import { EnhancedClaimsFormProps } from '../interface/EnhancedClaimsFormProps';

export const handleCauseOfDeath = (values: EnhancedClaimsFormProps) => {
  if (
    values.causeOfDeath === `Natural` &&
    values.naturalCauseOfDeathOptions === 'Other'
  ) {
    return values.causeOfDeathOther
      ? values.causeOfDeathOther
      : values.naturalCauseOfDeathOptions;
  }
  return values.causeOfDeath;
};

export const mapToClaimsRequest = (
  values: EnhancedClaimsFormProps,
): ClaimPostRequest => {
  return {
    claimRequest: {
      deceased: {
        firstName: values.deceasedFirstName || null,
        middleInitial: values.deceasedMiddleInitial || null,
        lastName: values.deceasedLastName || null,
        suffixName: values.deceasedSuffix || null,
        dateOfBirth: values.deceasedDateOfBirth.split('/').join('-') || null,
        dateOfDeath: values.deceasedDateOfDeath.split('/').join('-') || null,
        socialSecurityNumber: values.deceasedSocialSecurity || null,
        causeOfDeath: handleCauseOfDeath(values) || null,
        funeralHomeName: values.funeralHomeName || null,
        funeralHomeAddr1: values.funeralHomeAddress1 || null,
        funeralHomeAddr2: values.funeralHomeAddress2 || null,
        funeralHomeCity: values.funeralHomeCity || null,
        funeralHomeState: values.funeralHomeState || null,
        funeralHomeZip: values.funeralHomeZipCode || null,
        funeralHomePhone: values.funeralHomePhone || null,
        funeralHomeFax: values.funeralHomeFax || null,
        certificateOne: values.certificateNumberOne || null,
        certificateTwo: values.certificateNumberTwo || null,
        certificateThree: values.certificateNumberThree || null,
      },

      claimant: {
        relationship:
          values.relationshipToDeceased === 'Other' && values.relationshipOther
            ? values.relationshipOther
            : values.relationshipToDeceased || null,
        firstName: values.claimantFirstName || null,
        middleInitial: values.claimantMiddleInitial || null,
        lastName: values.claimantLastName || null,
        suffixName: values.claimantSuffix || null,
        address1: values.claimantAddress1 || null,
        address2: values.claimantAddress2 || null,
        city: values.claimantCity || null,
        state: values.claimantState || null,
        zipCode: values.claimantZipCode || null,
        phone: values.claimantPhone || null,
        phoneType: values.claimantPhoneType || null,
        email: values.claimantEmail || null,
        salesId: values.servicingRepSalesId || null,
        deliverForms: values.formsRecipient === `Yes` ? true : false,
        deliverCheck: values.deliverProceeds === `Yes` ? true : false,
        isBeneficiary:
          values.beneficiary === `Yes`
            ? true
            : values.beneficiary === `No`
            ? false
            : undefined,
        additionalInformation: values.additionalInformation || null,
        utmParameters: null,
        userJourney: null,
        preferredContactMethod: values.claimantPreferredContactMethod || null,
      },

      beneficiary: {
        isDeceased:
          values.beneficiaryDeceased === `Yes` ? true : false || undefined,
        dateOfDeath: values.beneficiaryDateOfDeath.split('/').join('-') || null,
        relationship: values.beneficiaryRelationToDeceased || null,
        firstName: values.beneficiaryFirstName || null,
        middleInitial: values.beneficiaryMiddleInitial || null,
        lastName: values.beneficiaryLastName || null,
        suffixName: values.beneficiarySuffix || null,
        address1: values.beneficiaryAddress1 || null,
        address2: values.beneficiaryAddress2 || null,
        city: values.beneficiaryCity || null,
        state: values.beneficiaryState || null,
        zipCode: values.beneficiaryZipCode || null,
        phone: values.beneficiaryPhone || null,
        email: values.beneficiaryEmail || null,
      },
    },
  };
};
