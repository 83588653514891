export const enum FORM_GROUPS {
  GROUP_1 = 'formGroup1',
  GROUP_2 = 'formGroup2',
}

export interface InputProps {
  type: string;
  name: string;
  label: string;
  required: boolean;
  hideLabel?: boolean;
  visible: boolean;
  autoComplete?: string | undefined;
  placeholder: string;
  options?: {
    [key: string]: string | {};
  };
  radioOptions?: string[];
  triggerField?: string | null | undefined;
  triggerValue?: string | null | undefined;
  width?: {} | string;
  maxLength?: number;
}

export interface InputGroupProps extends InputProps {
  inputGroupHeading: string;
  inputGroupSubheading?: string | undefined;
  visible: boolean;
  inputs: InputProps[];
}

export interface FormGroupProps extends InputGroupProps {
  disclaimer: string | undefined;
  visible: boolean;
  inputGroups: InputGroupProps[];
}

export interface FormStepProps extends FormGroupProps {
  name: string;
  visible: boolean;
  [FORM_GROUPS.GROUP_1]: FormGroupProps;
  [FORM_GROUPS.GROUP_2]?: FormGroupProps;
}
