import { FormikErrors } from 'formik';

import {
  FORM_GROUPS,
  FormStepProps,
  InputGroupProps,
} from '../../FormStep/interface/FormStepProps';
import { EnhancedClaimsFormProps } from '../../interface/EnhancedClaimsFormProps';
import { ActionTypes } from '../reducers/stepsReducer';
import { ACTIONS } from '../reducers/stepsReducer';

export interface ReflexiveInputType {
  formGroup: FORM_GROUPS;
  name: string;
  inputGroupIndex: number | string;
  inputIndex: number | string;
  triggerField: string;
  triggerValue?: string;
}

export const showNext = (formLength: number, step: number) => {
  return step < formLength - 1 ? true : false;
};

export const showPrevious = (formLength: number, step: number) => {
  return step > 0 && step < formLength ? true : false;
};

export const showSubmit = (formLength: number, step: number) => {
  return step === formLength - 1 ? true : false;
};

export const prevStepName = (activeStep: number, steps: { name: string }[]) => {
  return steps[activeStep - 1].name;
};

export const nextStepName = (activeStep: number, steps: { name: string }[]) => {
  return steps[activeStep + 1].name;
};

export const handleRootScroll = () => {
  if (window) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};

export const handleDispatch = (
  values: EnhancedClaimsFormProps,
  dispatch: React.Dispatch<ActionTypes>,
  activeStep: number,
) => {
  const {
    naturalCauseOfDeathOptions,
    causeOfDeath,
    relationshipToDeceased,
    beneficiary,
    beneficiaryDeceased,
    beneficiaryRelationToDeceased,
  } = values;
  if (activeStep === 0) {
    if (causeOfDeath === 'Natural') {
      dispatch({
        type: ACTIONS.SHOW_INPUT,
        payload: {
          stepName: 'Deceased Member',
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIdx: 4,
          inputIdx: 1,
          fieldName: 'naturalCauseOfDeathOptions',
        },
      });

      if (naturalCauseOfDeathOptions === 'Other') {
        dispatch({
          type: ACTIONS.SHOW_INPUT,
          payload: {
            stepName: 'Deceased Member',
            formGroup: FORM_GROUPS.GROUP_1,
            inputGroupIdx: 4,
            inputIdx: 2,
            fieldName: 'causeOfDeathOther',
          },
        });
      } else {
        dispatch({
          type: ACTIONS.HIDE_INPUT,
          payload: {
            stepName: 'Deceased Member',
            formGroup: FORM_GROUPS.GROUP_1,
            inputGroupIdx: 4,
            inputIdx: 2,
            fieldName: 'causeOfDeathOther',
          },
        });
      }
    } else if (causeOfDeath || causeOfDeath !== 'Natural') {
      dispatch({
        type: ACTIONS.HIDE_INPUT,
        payload: {
          stepName: 'Deceased Member',
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIdx: 4,
          inputIdx: 1,
          fieldName: 'naturalCauseOfDeathOptions',
        },
      });
      dispatch({
        type: ACTIONS.HIDE_INPUT,
        payload: {
          stepName: 'Deceased Member',
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIdx: 4,
          inputIdx: 2,
          fieldName: 'causeOfDeathOther',
        },
      });
    }
  }

  // relationship to deceased
  if (activeStep === 1) {
    if (relationshipToDeceased?.toLowerCase() !== `other`) {
      // hide other
      dispatch({
        type: ACTIONS.HIDE_INPUT,
        payload: {
          stepName: 'Your Information',
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIdx: 0,
          inputIdx: 1,
          fieldName: 'relationshipOther',
        },
      });
    } else {
      dispatch({
        type: ACTIONS.SHOW_INPUT,
        payload: {
          stepName: 'Your Information',
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIdx: 0,
          inputIdx: 1,
          fieldName: 'relationshipOther',
        },
      });
    }

    if (
      relationshipToDeceased?.toLowerCase() !== 'woodmenlife representative'
    ) {
      // hide sales
      dispatch({
        type: ACTIONS.HIDE_INPUT_GROUPS,
        payload: {
          stepIdx: 1,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [1, 2, 3],
        },
      });
    } else {
      // show sales
      dispatch({
        type: ACTIONS.SHOW_INPUT_GROUPS,
        payload: {
          stepIdx: 1,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [1, 2, 3],
        },
      });
    }

    if (
      !relationshipToDeceased ||
      relationshipToDeceased?.toLowerCase() === 'woodmenlife representative'
    ) {
      // hide contact
      dispatch({
        type: ACTIONS.HIDE_INPUT_GROUPS,
        payload: {
          stepIdx: 1,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [4, 5, 6, 7],
        },
      });
    }
    if (
      relationshipToDeceased?.toLowerCase() === 'spouse' ||
      relationshipToDeceased?.toLowerCase() === 'parent' ||
      relationshipToDeceased?.toLowerCase() === 'child' ||
      relationshipToDeceased?.toLowerCase() === 'other'
    ) {
      // show contact

      dispatch({
        type: ACTIONS.SHOW_INPUT_GROUPS,
        payload: {
          stepIdx: 1,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [4, 5, 6, 7],
        },
      });
    }
  }

  if (activeStep === 2) {
    if (!beneficiary || beneficiary === 'Unsure') {
      dispatch({
        type: ACTIONS.HIDE_INPUT_GROUPS,
        payload: {
          stepIdx: 2,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [1, 2, 3, 4, 5, 6],
        },
      });
    }

    if (beneficiary && beneficiary === 'Yes') {
      dispatch({
        type: ACTIONS.HIDE_INPUT_GROUPS,
        payload: {
          stepIdx: 2,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [1, 2, 3, 4, 5, 6],
        },
      });
    }

    if (beneficiary === 'No') {
      dispatch({
        type: ACTIONS.SHOW_INPUT_GROUPS,
        payload: {
          stepIdx: 2,
          formGroup: FORM_GROUPS.GROUP_1,
          inputGroupIndices: [1, 2],
        },
      });
      dispatch({
        type: ACTIONS.HIDE_INPUT,
        payload: {
          stepName: 'Beneficiary Information',
          inputGroupIdx: 2,
          formGroup: FORM_GROUPS.GROUP_1,
          inputIdx: 1,
        },
      });

      if (beneficiaryDeceased === 'No') {
        dispatch({
          type: ACTIONS.HIDE_INPUT,
          payload: {
            stepName: 'Beneficiary Information',
            inputGroupIdx: 2,
            formGroup: FORM_GROUPS.GROUP_1,
            inputIdx: 1,
          },
        });
        dispatch({
          type: ACTIONS.SHOW_INPUT_GROUPS,
          payload: {
            stepIdx: 2,
            formGroup: FORM_GROUPS.GROUP_1,
            inputGroupIndices: [4, 5],
          },
        });
        if (beneficiaryRelationToDeceased === 'Other') {
          dispatch({
            type: ACTIONS.SHOW_INPUT,
            payload: {
              stepIdx: 2,
              stepName: 'Beneficiary Information',
              formGroup: FORM_GROUPS.GROUP_1,
              inputGroupIdx: 3,
              inputIdx: 1,
              fieldName: 'beneficiaryrelationshipOther',
            },
          });
        }
      }

      if (beneficiaryDeceased === 'Yes') {
        dispatch({
          type: ACTIONS.HIDE_INPUT_GROUPS,
          payload: {
            stepIdx: 2,
            formGroup: FORM_GROUPS.GROUP_1,
            inputGroupIndices: [4, 5],
          },
        });
        dispatch({
          type: ACTIONS.SHOW_INPUT,
          payload: {
            stepName: 'Beneficiary Information',
            inputGroupIdx: 2,
            formGroup: FORM_GROUPS.GROUP_1,
            inputIdx: 1,
          },
        });
      }
    }
  }

  if (activeStep === 3) {
  }
};

export function isStepValid<EnhancedClaimsFormProps>(
  values: EnhancedClaimsFormProps,
  errors: FormikErrors<EnhancedClaimsFormProps>,
  activeStep: number,
  steps: FormStepProps[],
): boolean {
  let valuesCopy: any = JSON.parse(JSON.stringify(values));
  let errorsCopy: any = JSON.parse(JSON.stringify(errors));
  let isValid = true;
  steps[activeStep][FORM_GROUPS.GROUP_1].inputGroups.forEach(
    (ig: InputGroupProps) => {
      for (const value of Object.values(ig.inputs)) {
        if (
          value.required &&
          value.visible &&
          (!valuesCopy[value.name] || errorsCopy[value.name])
        ) {
          isValid = false;
        } else if (!value.required && value.visible && errorsCopy[value.name]) {
          isValid = false;
        }
      }
    },
  );
  if (steps[activeStep][FORM_GROUPS.GROUP_2]) {
    steps[activeStep][FORM_GROUPS.GROUP_2]!.inputGroups.forEach(
      (ig: InputGroupProps) => {
        for (const value of Object.values(ig.inputs)) {
          if (
            value.required &&
            value.visible &&
            (!valuesCopy[value.name] || errorsCopy[value.name])
          ) {
            isValid = false;
          } else if (
            !value.required &&
            value.visible &&
            errorsCopy[value.name]
          ) {
            isValid = false;
          }
        }
      },
    );
  }

  return isValid;
}
