import { Box, Flex, useBreakpointValue, VStack } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useReducer, useState } from 'react';
import { Reducer } from 'react';

import { WLButton, WLLink, WLText } from '../../../../design-library';
import { ChevronLeftIcon } from '../../../../design-library/Icons/index';
import FormStep from '../FormStep/FormStep';
import { FormStepProps } from '../FormStep/interface/FormStepProps';
import { EnhancedClaimsFormProps } from '../interface/EnhancedClaimsFormProps';
import { BusinessLogic } from './BusinessLogic/BusinessLogic';
import { LandingPageContext } from './context/StepContext';
import { StepContext, StepDispatchContext } from './context/StepContext';
import FormStepIndicator from './FormStepIndicator';
import { stepIndicatorState as stepIndicatorStore } from './FormStepIndicator/state/stepIndicatorState';
import FormStepperHeader from './FormStepperHeader';
import { formHeaderRichText } from './FormStepperHeader/FormStepperHeader';
import {
  handleDispatch,
  handleRootScroll,
  isStepValid,
  nextStepName,
  prevStepName,
  showNext,
  showPrevious,
  showSubmit,
} from './helpers/helpers';
import stepsReducer, { ActionTypes } from './reducers/stepsReducer';
import { steps as initialState } from './state/formState';
import { headerState as headerStore } from './state/headerState';

interface FormStepperProps {}
type HeaderStateProps = {
  headingText?: string;
  paragraph1?: { text: string; richText: formHeaderRichText };
  paragraph2?: { text: string; richText: formHeaderRichText };
};

type IndicatorCaption = string[];

const FormStepper: React.FC<FormStepperProps> = () => {
  const screen = useBreakpointValue(
    {
      base: 'mobile',
      md: 'desktop',
    },
    {
      fallback: 'mobile',
    },
  );
  const { values, initialValues, errors, isSubmitting, isValid, dirty } =
    useFormikContext<EnhancedClaimsFormProps>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, dispatch] = useReducer<Reducer<FormStepProps[], ActionTypes>>(
    stepsReducer,
    initialState as FormStepProps[],
  );
  const [indicatorCaption, setIndicatorCaption] =
    useState<IndicatorCaption>(stepIndicatorStore);
  const [headerState, setHeaderState] =
    useState<HeaderStateProps[]>(headerStore);

  React.useEffect(() => {
    handleRootScroll();
  }, [activeStep]);

  React.useEffect(() => {
    if (values !== initialValues) {
      handleDispatch({ ...values }, dispatch, activeStep);
    }
  }, [values, initialValues, activeStep]);

  return (
    <VStack paddingX={{ base: '19px', md: 'unset' }} spacing={14}>
      <BusinessLogic />
      <StepContext.Provider value={steps}>
        <VStack spacing={2.5} w="full">
          <Flex
            h={{ base: '104px', md: '150px' }}
            maxH="150px"
            alignItems="flex-end"
            pb="14px"
          >
            {/* add gradient  make container reusable*/}
            <FormStepIndicator
              activeStep={activeStep}
              length={steps.length}
              indicatorCaption={indicatorCaption[activeStep]}
              key={`step-indicator-${activeStep}`}
            />
          </Flex>
          <FormStepperHeader headerState={headerState[activeStep]} />
          <Box
            maxW="526px"
            bg="#FAFCFD"
            border="1px solid #B5D5F4"
            borderRadius="16px"
            padding={{ base: '8px 8px', md: '32px 24px' }}
          >
            <WLText
              textAlign={'center'}
              lineHeight="20px"
              fontWeight={'bold'}
              fontSize={{ base: 'sm', md: 'md' }}
            >
              Need Help?
            </WLText>
            <WLLink
              to="/find/rep"
              textDecoration={'none'}
              fontWeight={'bold'}
              fontSize={{ base: 'sm', md: 'md' }}
            >
              Contact a Rep
            </WLLink>{' '}
            or{' '}
            <WLLink
              to="tel:18002253108"
              textDecoration={'none'}
              fontWeight={'bold'}
              fontSize={{ base: 'sm', md: 'md' }}
            >
              1-800-225-3108
            </WLLink>
          </Box>
        </VStack>

        <StepDispatchContext.Provider value={dispatch}>
          <FormStep
            key={`step-${activeStep + 1}`}
            step={{ ...steps[activeStep] }}
          />
        </StepDispatchContext.Provider>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          justify="center"
          gap={{ base: '16px', md: '12px' }}
          spacing={0}
        >
          {activeStep === 0 && (
            <LandingPageContext.Consumer>
              {(context) => (
                <WLButton
                  textTransform="capitalize"
                  colorScheme="wl-white"
                  variant="solid"
                  fontSize="14px"
                  fontWeight="bold"
                  onClick={() => {
                    context.handleShowLandingPage();
                  }}
                >
                  <ChevronLeftIcon />
                  Introduction
                </WLButton>
              )}
            </LandingPageContext.Consumer>
          )}
          {showPrevious(steps.length, activeStep) && (
            <WLButton
              textTransform="capitalize"
              colorScheme="wl-white"
              variant="secondaryWhite"
              fontSize="14px"
              fontWeight="bold"
              onClick={() => setActiveStep(activeStep - 1)}
            >
              <ChevronLeftIcon />
              {screen === 'mobile'
                ? prevStepName(activeStep, steps)
                : `Step ${activeStep + 1 - 1}`}
            </WLButton>
          )}
          {showNext(steps.length, activeStep) && (
            <WLButton
              textTransform="capitalize"
              colorScheme="wl-blue"
              variant="primary"
              fontSize="14px"
              fontWeight="bold"
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              isDisabled={!isStepValid(values, errors, activeStep, steps)}
            >
              {screen === `mobile`
                ? `Step ${activeStep + 2}: ${nextStepName(activeStep, steps)}`
                : `Step ${activeStep + 2}`}
            </WLButton>
          )}
          {showSubmit(steps.length, activeStep) && (
            <WLButton
              textTransform="capitalize"
              colorScheme="wl-blue"
              variant="primary"
              fontSize="14px"
              fontWeight="bold"
              type="submit"
              isLoading={isSubmitting ? true : false}
              isDisabled={!(isValid && dirty)}
            >
              Submit
            </WLButton>
          )}
        </Stack>
      </StepContext.Provider>
    </VStack>
  );
};

export default FormStepper;
