import { FORM_GROUPS } from '../../FormStep/interface/FormStepProps';
export const StatesByAbbreviation = {
  '': '',
  AL: 'AL',
  AK: 'AK',
  AR: 'AR',
  AZ: 'AZ',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DC: 'DC',
  DE: 'DE',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  IA: 'IA',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  MA: 'MA',
  MD: 'MD',
  ME: 'ME',
  MI: 'MI',
  MN: 'MN',
  MO: 'MO',
  MS: 'MS',
  MT: 'MT',
  NC: 'NC',
  ND: 'ND',
  NE: 'NE',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NV: 'NV',
  NY: 'NY',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VA: 'VA',
  VT: 'VT',
  WA: 'WA',
  WI: 'WI',
  WV: 'WV',
  WY: 'WY',
};

export type StateAbbreviation = keyof typeof StatesByAbbreviation;

const small = { base: '100%', xs: '107px' };
const medium = { base: '100%', xs: '217px' };

const medToLarge = {
  base: '100%',
  xs: '217px',
  md: '337px',
};

const largeToSmall = {
  base: '100%',
  xs: 'auto',
  md: '107px',
};

const suffixOptions = {
  [``]: ``,
  II: `II`,
  III: `III`,
  IV: `IV`,
  JD: `J.D.`,
  JR: `Jr.`,
  MED: `M.Ed.`,
  PE: `PE`,
  PHD: `Ph.D.`,
  SR: `Sr.`,
};

const phoneTypeOptions = {
  [``]: `Choose a Phone Type`,
  Mobile: `Mobile`,
  Home: `Home`,
};

const causeOfDeathOptions = {
  [``]: '',
  Natural: `Natural`,
  Accidental: `Accidental`,
  Suicide: `Suicide`,
  Homicide: `Homicide`,
  Pending: `Pending`,
  Undetermined: `Undetermined`,
};

const relationshipOptions = {
  [``]: ``,
  [`Spouse`]: `Spouse`,
  [`Parent`]: `Parent`,
  [`Child`]: `Child`,
  [`Other`]: `Other`,
  [`WoodmenLife Representative`]: `WoodmenLife Representative`,
};

const beneficiaryRelationshipToDeceasedOptions = {
  [``]: ``,
  [`Spouse`]: `Spouse`,
  [`Parent`]: `Parent`,
  [`Child`]: `Child`,
  [`Sibling`]: `Sibling`,
  [`Other`]: `Other`,
};

export const steps = [
  // step 1
  {
    name: 'Deceased Member',
    // 1.1
    [FORM_GROUPS.GROUP_1]: {
      disclaimer: '*Denotes a Required Field',
      inputGroups: [
        {
          // 1.1.1
          inputGroupHeading: 'What is the name of the deceased member?',
          visible: true,
          inputs: [
            {
              type: 'text',
              name: 'deceasedFirstName',
              label: 'First Name',
              maxLength: 50,
              required: true,
              visible: true,
              placeholder: '',
              width: medToLarge,
            },
            {
              type: 'text',
              name: 'deceasedMiddleInitial',
              label: 'Middle Initial',
              maxLength: 1,
              required: false,
              visible: true,
              placeholder: '',
              width: small,
            },
            {
              type: 'text',
              name: 'deceasedLastName',
              label: 'Last Name',
              maxLength: 50,
              required: true,
              visible: true,
              placeholder: '',
              width: medToLarge,
            },
            {
              type: 'select',
              options: suffixOptions,
              name: 'deceasedSuffix',
              label: 'Suffix',
              placeholder: '',
              required: false,
              visible: true,
              width: small,
            },
          ],
        },
        {
          // 1.1.2
          inputGroupHeading: 'What is their certificate number?',
          inputGroupSubheading: 'List up to three',
          visible: true,
          inputs: [
            {
              type: 'text',
              name: 'certificateNumberOne',
              label: 'Certificate Number',
              required: false,
              visible: true,
              placeholder: '',
              maxLength: 12,
            },
            {
              type: 'text',
              name: 'certificateNumberTwo',
              label: 'Certificate Number',
              required: false,
              visible: true,
              placeholder: '',
              maxLength: 12,
            },
            {
              type: 'text',
              name: 'certificateNumberThree',
              label: 'Certificate Number',
              required: false,
              visible: true,
              placeholder: '',
              maxLength: 12,
            },
          ],
        },
        {
          // 1.1.2
          inputGroupHeading: 'What are the dates of their birth and death?',
          visible: true,
          inputs: [
            {
              type: 'text',
              name: 'deceasedDateOfBirth',
              label: 'Date of Birth',
              maxLength: 10,
              required: true,
              visible: true,
              width: { base: '100%', xs: '107px', md: '222px' },
              placeholder: 'MM/DD/YYYY',
            },
            {
              type: 'text',
              name: 'deceasedDateOfDeath',
              label: 'Date of Death',
              maxLength: 10,
              required: false,
              visible: true,
              width: { base: '100%', xs: '107px', md: '222px' },
              placeholder: 'MM/DD/YYYY',
            },
          ],
        },
        {
          // 1.1.3
          inputGroupHeading: 'What is their Social Security Number?',
          visible: true,
          inputs: [
            {
              type: 'ssn',
              name: 'deceasedSocialSecurity',
              label: 'Social Security Number',
              maxLength: 11,
              required: false,
              visible: true,
              placeholder: '###-##-####',
            },
          ],
        },
        {
          // 1.1.4
          inputGroupHeading: 'What is the manner or cause of their death?',
          visible: true,
          inputs: [
            {
              type: 'select',
              name: 'causeOfDeath',
              label: 'Manner / Cause of Death',
              required: true,
              visible: true,
              placeholder: '',
              options: causeOfDeathOptions,
            },
            {
              type: 'radio',
              name: 'naturalCauseOfDeathOptions',
              label: 'Please select the natural cause of death.',
              required: true,
              visible: false,
              placeholder: '',
              triggerField: 'causeOfDeath',
              triggerValue: 'Natural',
              formGroup: [FORM_GROUPS.GROUP_1],
              radioOptions: [
                'Dementia',
                'COPD',
                'Cancer',
                'Stroke',
                'Heart Disease',
                'Other',
              ],
            },
            {
              type: 'textarea',
              name: 'causeOfDeathOther',
              label: 'Please explain',
              required: false,
              visible: false,
              maxLength: 150,
              placeholder: '',
              triggerField: 'naturalCauseOfDeathOptions',
              triggerValue: 'Other',
              formGroup: [FORM_GROUPS.GROUP_1],
            },
          ],
        },
      ],
      visible: true,
    },
    // 1.2
    [FORM_GROUPS.GROUP_2]: {
      diclaimer: '',
      inputGroups: [
        // 1.2.1
        {
          inputGroupHeading:
            'Please provide the following funeral home information if available.',
          visible: true,
          inputs: [
            {
              type: 'text',
              name: 'funeralHomeName',
              label: 'Funeral Home Name',
              required: false,
              visible: true,
              placeholder: '',
              maxLength: 150,
            },
            {
              type: 'text',
              name: 'funeralHomeAddress1',
              maxLength: 150,
              label: 'Address 1',
              required: false,
              visible: true,
              placeholder: '',
            },
            {
              type: 'text',
              name: 'funeralHomeAddress2',
              maxLength: 50,
              label: 'Address 2',
              required: false,
              visible: true,
              placeholder: '',
            },
            {
              type: 'text',
              name: 'funeralHomeCity',
              maxLength: 50,
              label: 'City',
              required: false,
              visible: true,
              width: { base: '100%', xs: '217px' },
              placeholder: '',
            },
            {
              type: 'select',
              options: StatesByAbbreviation,
              name: 'funeralHomeState',
              label: 'State',
              required: false,
              visible: true,
              width: { base: '100%', xs: '107px' },
              placeholder: '',
            },
            {
              type: 'text',
              name: 'funeralHomeZipCode',
              label: 'Zip Code',
              autoComplete: 'false',
              maxLength: 5,
              required: false,
              visible: true,
              width: { base: '100%', xs: '107px' },
              placeholder: '',
            },
            {
              type: 'phone',
              name: 'funeralHomePhone',
              label: 'Phone',
              maxLength: 12,
              autoComplete: 'false',
              placeholder: '###-###-####',
              required: false,
              visible: true,
            },
            {
              type: 'phone',
              name: 'funeralHomeFax',
              label: 'Fax',
              maxLength: 12,
              autoComplete: 'false',
              placeholder: '###-###-####',
              required: false,
              visible: true,
            },
          ],
        },
      ],
      visible: true,
    },
  },
  // step 2
  {
    name: 'Your Information',
    // 2.1
    [FORM_GROUPS.GROUP_1]: {
      disclaimer: '*Denotes a Required Field',
      inputGroups: [
        {
          // 2.1.1
          inputGroupHeading: 'What is your relationship to the deceased?',
          visible: true,
          inputs: [
            {
              type: 'select',
              name: 'relationshipToDeceased',
              label: 'Relationship',
              required: true,
              options: relationshipOptions,
              visible: true,
              placeholder: '',
            },
            {
              type: 'text',
              name: 'relationshipOther',
              label: 'Please Provide Relationship',
              required: false,
              visible: false,
              placeholder: '',
              maxLength: 50,
            },
          ],
        },
        {
          inputGroupHeading: 'What is your Sales ID?',
          visible: false,
          inputs: [
            {
              type: 'text',
              name: 'servicingRepSalesId',
              label: 'Sales ID',
              required: true,
              visible: false,
              maxLength: 6,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              triggerValue: 'Servicing Representative',
            },
          ],
        },
        {
          inputGroupHeading:
            'Should the Home Office send claim forms to the beneficiary?*',
          visible: false,
          inputs: [
            {
              type: 'radio',
              name: 'formsRecipient',
              label: 'Form Recipient',
              required: true,
              hideLabel: true,
              visible: false,
              placeholder: '',
              radioOptions: ['Yes', 'No'],
              triggerField: 'relationshipToDeceased',
              triggerValue: 'Servicing Representative',
            },
          ],
        },
        {
          inputGroupHeading:
            'If this is a quick claim, would you like to deliver the check?*',
          visible: false,
          inputs: [
            {
              type: 'radio',
              name: 'deliverProceeds',
              label: 'Deliver Proceeds',
              hideLabel: true,
              required: true,
              visible: false,
              placeholder: '',
              radioOptions: ['Yes', 'No'],
              triggerField: 'relationshipToDeceased',
              triggerValue: 'Servicing Representative',
            },
          ],
        },

        {
          // 2.1.1
          inputGroupHeading: 'What is your name?',
          visible: false,
          inputs: [
            {
              type: 'text',
              name: 'claimantFirstName',
              label: 'First Name',
              maxLength: 50,
              required: true,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              width: medToLarge,
            },
            {
              type: 'text',
              name: 'claimantMiddleInitial',
              label: 'Middle Initial',
              maxLength: 1,
              required: false,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              width: small,
            },
            {
              type: 'text',
              name: 'claimantLastName',
              label: 'Last Name',
              maxLength: 50,
              required: true,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              width: medToLarge,
            },
            {
              type: 'select',
              options: suffixOptions,
              name: 'claimantSuffix',
              label: 'Suffix',
              required: false,
              visible: false,
              placeholder: '',
              width: small,
            },
          ],
        },
        {
          // 2.1.2
          inputGroupHeading: 'What is your address?',
          visible: false,
          inputs: [
            {
              type: 'text',
              name: 'claimantAddress1',
              label: 'Address 1',
              maxLength: 150,
              required: true,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
            },
            {
              type: 'text',
              name: 'claimantAddress2',
              maxLength: 50,
              label: 'Address 2',
              required: false,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
            },
            {
              type: 'text',
              name: 'claimantCity',
              label: 'City',
              maxLength: 50,
              required: true,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              width: medium,
            },
            {
              type: 'select',
              name: 'claimantState',
              label: 'State',
              required: true,
              options: StatesByAbbreviation,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              width: small,
            },
            {
              type: 'text',
              name: 'claimantZipCode',
              autoComplete: 'false',
              label: 'Zip Code',
              maxLength: 5,
              required: true,
              visible: false,
              placeholder: '#####',
              triggerField: 'relationshipToDeceased',
              width: largeToSmall,
            },
          ],
        },
        {
          // 2.1.3
          inputGroupHeading: 'How can we contact you?',
          visible: false,
          inputs: [
            {
              type: 'phone',
              name: 'claimantPhone',
              label: 'Phone',
              maxLength: 12,
              required: true,
              visible: false,
              autoComplete: 'false',
              placeholder: '###-###-####',
              triggerField: 'relationshipToDeceased',
            },
            {
              type: 'select',
              name: 'claimantPhoneType',
              label: 'Type',
              required: false,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
              options: phoneTypeOptions,
            },
            // {
            //   type: 'checkbox',
            //   name: 'acceptableContactMethodPhone',
            //   label: 'Preferred Contact Method',
            //   required: false,
            //   visible: false,
            //   placeholder: '',
            //   triggerField: 'relationshipToDeceased',
            // },
            {
              type: 'text',
              name: 'claimantEmail',
              maxLength: 150,
              label: 'Email',
              required: true,
              visible: false,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
            },
          ],
        },
        {
          // 2.1.4
          inputGroupHeading: 'What method do you prefer?',
          visible: false,
          inputs: [
            {
              type: 'radio',
              name: 'claimantPreferredContactMethod',
              label: '',
              hideLabel: false,
              radioOptions: [
                { value: 'phone', label: 'I prefer a phone call' },
                { value: 'email', label: 'I prefer an email' },
              ],

              required: false,
              visible: true,
              placeholder: '',
              triggerField: 'relationshipToDeceased',
            },
          ],
        },
      ],
      visible: true,
    },
  },
  // step 3
  {
    name: 'Beneficiary Information',
    [FORM_GROUPS.GROUP_1]: {
      disclaimer: '*Denotes a Required Field',
      inputGroups: [
        {
          inputGroupHeading: 'Are you a beneficiary?*',
          visible: true,
          inputs: [
            {
              type: 'radio',
              name: 'beneficiary',
              label: '',
              hideLabel: true,
              radioOptions: ['Yes', 'No', 'Unsure'],
              required: true,
              visible: true,
              placeholder: '',
            },
          ],
        },
        {
          inputGroupHeading: `Please provide a beneficiary name listed on the policy.`,
          visible: false,
          inputs: [
            {
              type: 'text',
              name: 'beneficiaryFirstName',
              maxLength: 50,
              label: 'First Name',
              required: true,
              visible: false,
              width: medToLarge,
              placeholder: '',
              triggerField: 'beneficiaryDeceased',
              triggerValue: 'No',
            },
            {
              type: 'text',
              name: 'beneficiaryMiddleInitial',
              label: 'Middle Initial',
              required: false,
              visible: false,
              placeholder: '',
              width: small,
              maxLength: 1,
              triggerField: 'beneficiaryDeceased',
              triggerValue: 'No',
            },
            {
              type: 'text',
              name: 'beneficiaryLastName',
              label: 'Last Name',
              maxLength: 50,
              required: true,
              visible: false,
              width: medToLarge,
              placeholder: '',
              triggerField: 'beneficiaryDeceased',
              triggerValue: 'No',
            },
            {
              type: 'select',
              name: 'beneficiarySuffix',
              label: 'Suffix',
              required: false,
              visible: false,
              options: suffixOptions,
              placeholder: '',
              triggerField: 'beneficiaryDeceased',
              triggerValue: 'No',
              width: small,
            },
          ],
        },
        {
          inputGroupHeading: 'Is this beneficiary deceased?',
          visible: false,
          inputs: [
            {
              type: 'radio',
              name: 'beneficiaryDeceased',
              label: '',
              radioOptions: ['Yes', 'No'],
              required: false,
              visible: true,
              placeholder: '',
              triggerField: 'beneficiary',
              triggerValue: 'No',
            },
            {
              type: 'text',
              name: 'beneficiaryDateOfDeath',
              maxLength: 10,
              label: 'Date Of Death',
              required: false,
              visible: false,
              placeholder: 'MM/DD/YYYY',
              triggerField: 'beneficiaryDeceased',
              triggerValue: 'Yes',
            },
          ],
        },
        {
          inputGroupHeading: `What is this beneficiary’s relationship to the deceased?`,
          visible: false,
          inputs: [
            {
              type: 'select',
              options: beneficiaryRelationshipToDeceasedOptions,
              name: 'beneficiaryRelationshipToDeceased',
              label: 'Relationship',
              required: false,
              visible: false,
              placeholder: '',
              triggerField: 'beneficiaryDeceased',
              triggerValue: 'No',
            },
            {
              type: 'text',
              name: 'beneficiaryrelationshipOther',
              maxLength: 50,
              label: 'Please Provide Relationship',
              required: false,
              visible: false,
              placeholder: '',
            },
          ],
        },

        {
          inputGroupHeading: `What is this beneficiary’s address?`,
          visible: false,
          inputs: [
            {
              type: 'text',
              name: 'beneficiaryAddress1',
              label: 'Address 1',
              maxLength: 150,
              required: false,
              visible: false,
              placeholder: '',
            },
            {
              type: 'text',
              name: 'beneficiaryAddress2',
              label: 'Address 2',
              maxLength: 50,
              required: false,
              visible: false,
              placeholder: '',
            },
            {
              type: 'text',
              name: 'beneficiaryCity',
              maxLength: 50,
              label: 'City',
              required: false,
              visible: false,
              width: medium,
              placeholder: '',
            },
            {
              type: 'select',
              name: 'beneficiaryState',
              label: 'State',
              required: false,
              visible: false,
              width: small,
              options: StatesByAbbreviation,
              placeholder: '',
            },
            {
              type: 'text',
              name: 'beneficiaryZipCode',
              autoComplete: 'false',
              label: 'Zip Code',
              maxLength: 5,
              required: false,
              visible: false,
              placeholder: '#####',
              width: largeToSmall,
            },
          ],
        },
        {
          inputGroupHeading: `How can we contact this beneficiary?`,
          visible: false,
          inputs: [
            {
              type: 'phone',
              name: 'beneficiaryPhone',
              label: 'Phone',
              maxLength: 12,
              autoComplete: 'false',
              required: false,
              visible: false,
              placeholder: '###-###-####',
            },
            {
              type: 'text',
              name: 'beneficiaryEmail',
              label: 'Email',
              maxLength: 150,
              required: false,
              visible: false,
              placeholder: '',
            },
          ],
        },
      ],
      visible: true,
    },
  },
  // step 4
  {
    name: 'Additional Information',
    [FORM_GROUPS.GROUP_1]: {
      disclaimer: '',
      inputGroups: [
        {
          inputGroupHeading: 'Is there anything else you can share with us?',
          visible: true,
          inputs: [
            {
              type: 'textarea',
              name: 'additionalInformation',
              label: 'Additional Notes',
              maxLength: 500,
              required: false,
              visible: true,
              placeholder: '',
            },
          ],
        },
      ],
      visible: true,
    },
  },
];
