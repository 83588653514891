import { ApiConfiguration, ProducerApi } from '../apis/';

export const isValidZipCode = (value: string) =>
  /^\d{5}$/.test(value) && !/^9{5}$/.test(value) && !/^0{5}$/.test(value);

export const isAvailableZipCode = async (value: string) => {
  const api = new ProducerApi(
    new ApiConfiguration({
      basePath: process.env.GATSBY_PUBLIC_API_PATH,
    }),
  );

  const results =
    (await api.producerSearchPost({
      findProducerRequest: {
        zipCode: value,
        name: null,
      },
    })) || {};

  return results &&
    results.length === 1 &&
    results[0].productAvailability?.toLowerCase() === 'none'
    ? false
    : true;
};

export const isValidPhoneNumber = (value: string) =>
  /^[2-9]\d{2}-[2-9]\d{2}-\d{4}$/.test(value) &&
  !/911-/.test(value) && // Don't allow phone numbers with '911' area code or prefix
  !/^900-/.test(value) && // Don't allow toll numbers
  !/^555-/.test(value); // Don't allow numbers with 555 area code (fake)

export const isValidEmail = (value: string) => {
  return /^[A-Za-z0-9_\-.]+@[A-Za-z0-9_\-.]+\.[A-Za-z]{2,4}$/.test(value);
};

export const isZipValidationError = async (error: any) => {
  let parsedError;
  try {
    parsedError = await error.json();
  } catch {
    /* ignore */
  }

  try {
    if (parsedError?.detail === 'Invalid ZipCode') {
      return true;
    } else if (!parsedError && error.status === 422) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const isValidDateString = (value: any) =>
  /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/((?:19|20)\d\d)$/.test(value);

export const isValidSSN = (value: any) =>
  /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value);

export const isDateLessThan = (initialDate: any, comparisonDate: any) =>
  Date.parse(initialDate) < Date.parse(comparisonDate) ? true : false;

export const isNumeric = (val: any) => {
  return !isNaN(parseFloat(val)) && isFinite(val);
};

export const containsNumber = (val: any) => /^(?=.*[0-9])/.test(val);
export const isValidName = (value: any) => /^\d$/.test(value);

export const isNumber = (val: any) => /^\d+$/.test(val);
