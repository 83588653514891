import { VStack } from '@chakra-ui/layout';
import React from 'react';

import { WLH2, WLText } from '../../../../../design-library';
import { getRenderers } from './helpers/renderHelpers';
export enum formHeaderRichText {
  bold = 'bold',
  italic = 'italic',
  none = '',
}

export interface FormStepperHeaderProps {
  headerState: {
    headingText?: string;
    paragraph1?: { text: string; richText: formHeaderRichText };
    paragraph2?: { text: string; richText: formHeaderRichText };
  };
}

const FormStepperHeader: React.FC<FormStepperHeaderProps> = ({
  headerState,
}) => {
  const defaultParagraph = { text: '', richText: formHeaderRichText.none };
  const {
    headingText = '',
    paragraph1 = defaultParagraph,
    paragraph2 = defaultParagraph,
  } = headerState;

  return (
    <VStack maxW="540px" spacing={paragraph1.text ? 6 : 0}>
      {headingText && (
        <WLH2
          fontSize={{ base: '32px', md: '56px' }}
          color="wl-blue.navy"
          lineHeight={{ base: '36px', md: '64px' }}
          letterSpacing={{ base: '-.035em', md: '-.025em' }}
          textAlign="center"
          style={{ marginBottom: paragraph1.text ? '24px' : '0px' }}
        >
          {headingText}
        </WLH2>
      )}
      {paragraph1.text && (
        <WLText
          as="p"
          fontSize={{ base: '16px', md: '18px' }}
          lineHeight="22px"
          color="#3D3C3C"
          textAlign="center"
          style={{ marginTop: '0px', marginBottom: '24px' }}
        >
          {getRenderers(paragraph1).map((el: React.ReactNode | string) => {
            return el;
          })}
        </WLText>
      )}
      {paragraph2.text && (
        <WLText
          as="p"
          fontSize={{ base: '16px', md: '18px' }}
          lineHeight="22px"
          color="#3D3C3C"
          textAlign="center"
          style={{ marginTop: '0px', marginBottom: '24px' }}
        >
          {getRenderers(paragraph2).map((el: React.ReactNode | string) => {
            return el;
          })}
        </WLText>
      )}
    </VStack>
  );
};

export default FormStepperHeader;
