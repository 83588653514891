import { formHeaderRichText } from '../FormStepperHeader/FormStepperHeader';

export const headerState = [
  {
    headingText: `Deceased Member’s Information`,
    paragraph1: { text: '', richText: formHeaderRichText.none },
    paragraph2: { text: '', richText: formHeaderRichText.none },
  },
  {
    headingText: `Your Information`,
    paragraph1: { text: '', richText: formHeaderRichText.none },
    paragraph2: { text: '', richText: formHeaderRichText.none },
  },
  {
    headingText: 'Beneficiary Information',
    paragraph1: {
      text: ``,
      richText: formHeaderRichText.none,
    },
    paragraph2: {
      text: ``,
      richText: formHeaderRichText.bold,
    },
  },
  {
    headingText: 'Additional Information',
    paragraph1: {
      text: ``,
      richText: formHeaderRichText.none,
    },
    paragraph2: {
      text: ``,
      richText: formHeaderRichText.none,
    },
  },
];
