import {
  FORM_GROUPS,
  FormStepProps,
  InputGroupProps,
  InputProps,
} from '../../FormStep/interface/FormStepProps';
import { STEP_NAMES } from '../constants/stepConstants';
import { steps } from '../state/formState';

export enum ACTIONS {
  SHOW_INPUT_GROUPS = 'show-input-groups',
  HIDE_INPUT_GROUPS = 'hide-input-groups',
  SHOW_INPUT_GROUP = 'show-input-group',
  HIDE_INPUT_GROUP = 'hide-input-group',
  SHOW_INPUT = 'show-input',
  HIDE_INPUT = 'hide-input',
  HIDE_INPUTS = 'hide-inputs',
  CLEAR_INPUTS = 'clear-inputs',
}

export interface ActionTypes {
  type: ACTIONS;
  payload: {
    stepName?: string;
    stepIdx?: number;
    fieldName?: string;
    value?: string;
    activeStep?: number | string;
    formGroup?: FORM_GROUPS;
    inputGroupIdx?: number | string;
    inputGroupIndices?: number[];
    inputIdx?: number | string;
    inputsIndices?: number[] | string[];
  };
}

export default function stepsReducer(
  steps: FormStepProps[],
  action: ActionTypes,
): FormStepProps[] {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SHOW_INPUT_GROUP: {
      return steps.map((step: any, idx: number) => {
        if (idx === payload.stepIdx) {
          step.formGroup1.inputGroups.map(
            (inputGroup: any, inputGroupIdx: number) => {
              if (inputGroupIdx === payload.inputGroupIdx) {
                inputGroup.visible = true;
                inputGroup.inputs.map((input: any, inputIdx: number) => {
                  input.visible = true;
                  return input;
                });
              }
              return inputGroup;
            },
          );
        }
        return step;
      });
    }
    case ACTIONS.HIDE_INPUT_GROUP: {
      return steps.map((step: FormStepProps, idx: number) => {
        if (idx === payload.stepIdx) {
          step.formGroup1.inputGroups.map(
            (inputGroup: InputGroupProps, inputGroupIdx: number) => {
              if (inputGroupIdx === payload.inputGroupIdx) {
                inputGroup.visible = false;
                inputGroup.inputs.map((input: InputProps) => {
                  input.visible = false;
                  return input;
                });
              }
              return inputGroup;
            },
          );
        }
        return step;
      });
    }
    case ACTIONS.SHOW_INPUT_GROUPS: {
      return steps.map((step: any, stepIdx: number) => {
        if (stepIdx === payload.stepIdx) {
          if (payload.formGroup) {
            step[`${payload.formGroup}`].inputGroups.map(
              (inputGroup: any, inputGroupIdx: number) => {
                if (payload.inputGroupIndices?.includes(inputGroupIdx)) {
                  inputGroup.inputs.map((input: InputProps) => {
                    return (input.visible = true);
                  });
                  return (inputGroup.visible = true);
                }
                return inputGroup;
              },
            );
          }
          return step;
        }
        return step;
      });
    }
    case ACTIONS.HIDE_INPUT_GROUPS: {
      return steps.map((step: any, stepIdx: number) => {
        if (stepIdx === payload.stepIdx) {
          if (payload.formGroup) {
            step[`${payload.formGroup}`].inputGroups.map(
              (inputGroup: InputGroupProps, inputGroupIdx: number) => {
                payload.inputGroupIndices?.forEach(
                  (payloadIndex: number | string) => {
                    inputGroup.inputs.map((input: InputProps) => {
                      if (inputGroupIdx === payloadIndex) {
                        input.visible = false;
                        inputGroup.visible = false;
                      }

                      return input;
                    });
                  },
                );
                return inputGroup;
              },
            );
          }
          return step;
        }
        return step;
      });
    }
    case ACTIONS.SHOW_INPUT: {
      return steps.map((step: any, idx: number) => {
        if (step.name === payload.stepName) {
          if (payload.formGroup) {
            step[`${payload.formGroup}`].inputGroups.map(
              (inputGroup: any, inputGroupIdx: number) => {
                if (inputGroupIdx === payload.inputGroupIdx) {
                  inputGroup.inputs.map((input: any, inputIdx: number) => {
                    if (inputIdx === payload.inputIdx) {
                      input.visible = true;
                    }
                    return input;
                  });

                  return inputGroup;
                }
                return inputGroup;
              },
            );
          }
          return step;
        }
        return step;
      });
    }
    case ACTIONS.HIDE_INPUT: {
      return steps.map((step: any, idx: number) => {
        if (step.name === payload.stepName) {
          step[`${payload.formGroup}`].inputGroups.map(
            (inputGroup: any, inputGroupIdx: number) => {
              if (inputGroupIdx === payload.inputGroupIdx) {
                inputGroup.inputs.map((input: any, inputIdx: number) => {
                  if (inputIdx === payload.inputIdx) {
                    input.visible = false;
                  }
                  return input;
                });
                return inputGroup;
              }
              return inputGroup;
            },
          );
        }
        return step;
      });
    }
    case ACTIONS.HIDE_INPUTS: {
      return steps.map((step: any, stepIdx: number) => {
        if (stepIdx === payload.stepIdx) {
          if (payload.formGroup) {
            step[`${payload.formGroup}`].inputGroups.map(
              (inputGroup: any, inputGroupIdx: number) => {
                if (payload.inputGroupIndices?.includes(inputGroupIdx)) {
                  inputGroup.inputs.map((input: InputProps) => {
                    return (input.visible = true);
                  });
                  return (inputGroup.visible = true);
                }
                return inputGroup;
              },
            );
          }
          return step;
        }
        return step;
      });
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
