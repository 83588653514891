import { VStack } from '@chakra-ui/react';
import React from 'react';

import { WLH3, WLText } from '../../../../../design-library';

interface RendererProps {
  children?: React.ReactNode | React.ReactNode[];
}
interface FormGroupDisclaimerProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const FormGroupDisclaimer: React.FC<FormGroupDisclaimerProps> = ({
  children,
}) => {
  return (
    <WLText as="p" color="#797877" fontSize="15px" textAlign="left" mb={6}>
      {children}
    </WLText>
  );
};

interface FormGroupWrapperProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const FormGroupWrapper: React.FC<FormGroupWrapperProps> = ({
  children,
}) => {
  return (
    <VStack
      width="full"
      maxW="527px"
      alignItems="flex-start"
      bg={{ base: 'unset', md: '#F4F1EF' }}
      borderRadius="16px"
      border="1px"
      borderColor={{ base: 'transparent', md: '#DBD9D7' }}
      paddingX={{ base: 'unset', md: '34px' }}
      paddingY={{ base: 'unset', md: '56px' }}
      spacing={14}
    >
      {children}
    </VStack>
  );
};
interface InputGroupHeadingProps {
  children?: React.ReactNode | React.ReactNode[];
}
export const InputGroupHeading: React.FC<InputGroupHeadingProps> = ({
  children,
}) => {
  return (
    <WLH3
      fontSize={{ base: '20px', md: '24px' }}
      fontWeight="normal"
      mb={6}
      alignSelf="flex-start"
      color="#003366"
    >
      {children}
    </WLH3>
  );
};
