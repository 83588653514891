import { VStack } from '@chakra-ui/react';
import { Form, FormikProps, withFormik } from 'formik';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import { ApiConfiguration } from '../../../apis';
import { ClaimApi } from '../../../apis/generated/apis/ClaimApi';
import ClaimsLandingPage from '../ClaimsLandingPage';
import ClaimsFormValidator from './ClaimsForm.validator';
import FormStepper from './FormStepper';
import { LandingPageContext } from './FormStepper/context/StepContext';
import { EnhancedClaimsFormProps } from './interface/EnhancedClaimsFormProps';
import { mapToClaimsRequest } from './utils/claims-utils';

interface ClaimsFormProps {}
const ClaimsForm: React.FC<
  ClaimsFormProps & FormikProps<EnhancedClaimsFormProps>
> = ({ handleSubmit, status, isSubmitting, values }) => {
  const mixpanel = useMixpanel();
  const [showClaimsForm, setShowClaimsForm] = React.useState<boolean>(false);
  const [showLandingPage, setShowLandingPage] = React.useState<boolean>(true);
  const [type, setType] = React.useState<'begin' | 'end'>('begin');

  const handleShowClaimsForm = (): void => {
    setShowLandingPage(false);
    setShowClaimsForm(true);
  };

  const handleShowLandingPage = (): void => {
    setType('begin');
    setShowClaimsForm(false);
    setShowLandingPage(true);
  };

  React.useEffect(() => {
    if (status === 'success' && isSubmitting === false) {
      setType('end');
      setShowClaimsForm(false);
      setShowLandingPage(true);
      mixpanel.track('Claims - Submit', {
        Clicked: true,
        SalesID: values.servicingRepSalesId || 'N/A',
        Success: true,
      });
    } else if (status === 'error' && isSubmitting === false) {
      setType('end');
      setShowClaimsForm(false);
      setShowLandingPage(true);
      mixpanel.track('Claims - Submit', {
        Clicked: true,
        SalesID: values.servicingRepSalesId || 'N/A',
        Success: false,
      });
    }
  }, [status, isSubmitting, mixpanel]);

  React.useEffect(() => {
    mixpanel.track('Claims - Start', {
      Viewed: true,
      'Start Time': Math.floor(Date.now() / 1000),
    });
    return () => {};
  }, [mixpanel]);

  return (
    <VStack width="full" alignItems="center">
      <LandingPageContext.Provider
        value={{
          handleShowClaimsForm,
          handleShowLandingPage,
        }}
      >
        {showLandingPage && <ClaimsLandingPage type={type} />}
        {showClaimsForm && (
          <Form onSubmit={handleSubmit}>
            <FormStepper />
          </Form>
        )}
      </LandingPageContext.Provider>
    </VStack>
  );
};

const EnhancedClaimsForm = withFormik<ClaimsFormProps, EnhancedClaimsFormProps>(
  {
    handleSubmit: async (values, formikBag): Promise<void> => {
      const { setSubmitting, setStatus } = formikBag;
      setSubmitting(true);
      // api submit logic goes here
      try {
        const api = new ClaimApi(
          new ApiConfiguration({
            basePath: process.env.GATSBY_PUBLIC_API_PATH,
          }),
        );

        // claim request

        const request = mapToClaimsRequest(values);

        await api.claimPost(request);

        setStatus('success');
        setSubmitting(false);
      } catch (error: any) {
        setStatus('error');
        setSubmitting(false);
      }
    },
    mapPropsToValues: (props) => ({
      deceasedFirstName: '',
      deceasedMiddleInitial: '',
      deceasedLastName: '',
      deceasedSuffix: '',
      certificateNumberOne: '',
      certificateNumberTwo: '',
      certificateNumberThree: '',
      deceasedDateOfBirth: '',
      deceasedDateOfDeath: '',
      deceasedSocialSecurity: '',
      causeOfDeath: '',
      causeOfDeathOther: '',
      naturalCauseOfDeathOptions: '',
      funeralHomeName: '',
      funeralHomeAddress1: '',
      funeralHomeAddress2: '',
      funeralHomeCity: '',
      funeralHomeState: '',
      funeralHomeZipCode: '',
      funeralHomePhone: '',
      funeralHomeFax: '',
      relationshipToDeceased: '',
      relationshipOther: '',
      servicingRepSalesId: '',
      formsRecipient: '',
      deliverProceeds: '',
      claimantFirstName: '',
      claimantMiddleInitial: '',
      claimantLastName: '',
      claimantSuffix: '',
      claimantAddress1: '',
      claimantAddress2: '',
      claimantCity: '',
      claimantState: '',
      claimantZipCode: '',
      claimantPhone: '',
      claimantPhoneType: '',
      claimantEmail: '',
      claimantPreferredContactMethod: '',
      beneficiary: '',
      beneficiaryDeceased: '',
      beneficiaryDateOfDeath: '',
      beneficiaryFirstName: '',
      beneficiaryLastName: '',
      beneficiaryMiddleInitial: '',
      beneficiarySuffix: '',
      beneficiaryAddress1: '',
      beneficiaryAddress2: '',
      beneficiaryCity: '',
      beneficiaryState: '',
      beneficiaryZipCode: '',
      beneficiaryPhone: '',
      beneficiaryEmail: '',
      beneficiaryRelationToDeceased: '',
      additionalInformation: '',
    }),
    validate: ClaimsFormValidator,

    displayName: 'ClaimsForm',
  },
)(ClaimsForm);

export default EnhancedClaimsForm;
