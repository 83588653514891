// Utilities

export default {
  isNumeric(val) {
    return !isNaN(parseFloat(val)) && isFinite(val);
  },
  isEmpty(val) {
    const regEx = /^\s{1,}$/g; // Match any white space including space, tab, form-feed, etc.
    return String(val).length === 0 ||
      String(val) === null ||
      String(val).search(regEx) > -1
      ? true
      : false;
  },
  isDesktop() {
    return window.innerWidth >= 1024 ? true : false;
  },
  validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  },
  validatePhoneNumber(val) {
    const phoneNumber = this.formatPhoneNumber(val);
    return phoneNumber !== '' && phoneNumber !== null;
  },
  formatPhoneNumber(phoneNumber) {
    const formattedPhoneNumber = ('' + phoneNumber).replace(/\D/g, '');
    const match = formattedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return null;
  },
  validateZIPNotNewYork(zipcode) {
    if (
      (zipcode >= 10000 && zipcode <= 14999) ||
      zipcode == 6390 ||
      zipcode == 501 ||
      zipcode == 544
    ) {
      return false;
    } else {
      return true;
    }
  },
  validateZIP(val) {
    return /(^\d{5}$)/.test(val);
  },
  numFormat(val) {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(val);
  },
  numFormatUSD(val) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    return formatter.format(val);
  },
  numFormatUSDRound(val) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    return formatter.format(val);
  },
  affixScriptToHead(url, onloadFunction) {
    let newScript = document.createElement('script');
    if (onloadFunction) newScript.onload = onloadFunction;
    document.head.appendChild(newScript);
    if (!this.isEmpty(url)) newScript.src = url;
  },
  scrollStep() {
    // Get the height equivalent of the app's margin-top and padding-top values.
    const style = window.getComputedStyle(document.getElementById('app'));
    const styleHeight =
      parseFloat(style.marginTop, 10) + parseFloat(style.paddingTop, 10);

    // TODO: Account for headerHeight once the left and right column headers have been redesigned. -NE 20210412
    // Get combined height of column headers.
    // const leftColumnHeaderHeight = (document.querySelector('.LeftColumn__Header')) ? document.querySelector('.LeftColumn__Header').offsetHeight : 0
    // const rightColumnHeaderHeight = (document.querySelector('.RightColumn__Header')) ? document.querySelector('.RightColumn__Header').offsetHeight : 0
    // const headerHeight = leftColumnHeaderHeight + rightColumnHeaderHeight

    // Get the height of the mobile header container if it exists, otherwise set it to 0.
    const mobileHeaderHeight = document.getElementById('mobileHeaderContainer')
      ? document.getElementById('mobileHeaderContainer').offsetHeight
      : 0;
    // If mobile...
    if (!this.isDesktop()) {
      // Smooth scroll to the top of the page offset by the mobile header height and the combined column header heights.
      window.scrollTo({
        // TODO: Account for headerHeight once the left and right column headers have been redesigned. -NE 20210412
        // top: 0 - mobileHeaderHeight + headerHeight + styleHeight,
        top: 0 - mobileHeaderHeight + styleHeight,
        behavior: 'smooth'
      });
    }
    // Else...
    else {
      // Smooth scroll the entire app into view.
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
  }
};

export const isEmpty = (val) => {
  const regEx = /^\s{1,}$/g; // Match any white space including space, tab, form-feed, etc.
  return String(val).length === 0 ||
    String(val) === null ||
    String(val).search(regEx) > -1
    ? true
    : false;
}