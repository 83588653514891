import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import React from 'react';

import {
  trackStepEndMixpanel,
  trackStepStartMixpanel,
} from '../../../../utils/mixpanelUtils';
import InputGenerator from '../InputGenerator';
import { EnhancedClaimsFormProps } from '../interface/EnhancedClaimsFormProps';
import {
  FormGroupDisclaimer,
  FormGroupWrapper,
  InputGroupHeading,
} from './FormStepRenderers/FormStepRenderers';
import { FormStepProps } from './interface/FormStepProps';
interface StepProps {
  step: FormStepProps;
}
const FormStep: React.FC<StepProps> = ({ step }: StepProps) => {
  const mixpanel = useMixpanel();
  const { values } = useFormikContext<EnhancedClaimsFormProps>();

  const { formGroup1, formGroup2 = null } = step;

  React.useEffect(() => {
    trackStepStartMixpanel(step.name, mixpanel);
    return () => {
      trackStepEndMixpanel(step.name, mixpanel);
    };
  }, [step.name, mixpanel]);

  return (
    <VStack maxW="526px" width="full" spacing={14} textAlign="left">
      <FormGroupWrapper>
        <FormGroupDisclaimer>{formGroup1.disclaimer}</FormGroupDisclaimer>
        {formGroup1.visible &&
          formGroup1.inputGroups.map((inputGroup, idx) => {
            return inputGroup.visible ? (
              <VStack key={`input-group-${idx}-form-group-1`} w="full">
                <InputGroupHeading>
                  {inputGroup.inputGroupHeading}
                </InputGroupHeading>
                {inputGroup.inputGroupSubheading && (
                  <Text
                    fontSize="14px"
                    color="#767676"
                    textAlign={'left'}
                    style={{ marginTop: '0px' }}
                    width="100%"
                  >
                    {inputGroup.inputGroupSubheading}
                  </Text>
                )}
                <HStack
                  flexWrap="wrap"
                  gap="12px"
                  w="full"
                  mb={14}
                  justifyContent="space-between"
                >
                  {inputGroup.inputs.map((input, idx) => {
                    return (
                      input.visible && (
                        <>
                          <Box
                            w={input.width || '100%'}
                            flexGrow="1"
                            style={{ marginInlineStart: '0px' }}
                          >
                            <InputGenerator
                              key={`input-generator-${idx}`}
                              input={input}
                            />
                          </Box>
                          {input.name === `beneficiary` &&
                            values[
                              input.name as EnhancedClaimsFormProps['beneficiary']
                            ] && (
                              <Center
                                flexDirection={'column'}
                                padding={{ base: '8px 8px', md: '32px 24px' }}
                                maxW="526px"
                                bg="#FAFCFD"
                                border="1px solid #B5D5F4"
                                borderRadius="16px"
                              >
                                <Text
                                  fontSize="sm"
                                  fontWeight="bold"
                                  color="wl-blue.navy"
                                  textAlign={'center'}
                                >
                                  Please Note:
                                </Text>
                                <Text fontSize="sm" textAlign={'center'}>
                                  Death proceeds will be paid according to the
                                  beneficiary designation record at the Home
                                  Office.
                                </Text>
                              </Center>
                            )}
                        </>
                      )
                    );
                  })}
                </HStack>
              </VStack>
            ) : (
              <></>
            );
          })}
      </FormGroupWrapper>

      {formGroup2?.visible && (
        <FormGroupWrapper>
          {formGroup2?.disclaimer && (
            <FormGroupDisclaimer>{formGroup2.disclaimer}</FormGroupDisclaimer>
          )}
          {formGroup2.visible &&
            formGroup2.inputGroups.map((inputGroup, idx) => {
              return inputGroup.visible ? (
                <VStack key={`input-group-${idx}-form-group-2`} w="full">
                  <InputGroupHeading>
                    {inputGroup.inputGroupHeading}
                  </InputGroupHeading>
                  <HStack
                    flexWrap="wrap"
                    gap="12px"
                    w="full"
                    mb={14}
                    justifyContent="space-between"
                  >
                    {inputGroup.inputs.map((input, idx) => {
                      return (
                        input.visible && (
                          <Box
                            w={input.width || '100%'}
                            flexGrow="1"
                            style={{ marginInlineStart: '0px' }}
                          >
                            <InputGenerator
                              key={`input-generator-${idx}`}
                              input={input}
                            />
                          </Box>
                        )
                      );
                    })}
                  </HStack>
                </VStack>
              ) : (
                <></>
              );
            })}
        </FormGroupWrapper>
      )}
    </VStack>
  );
};

export default FormStep;
