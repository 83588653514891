import { Box, HStack, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import Overline from '../../../../../design-library/Overline';
interface StepIndicatorItems {
  length: number;
  activeStep: number;
  indicatorCaption: string;
}

interface StepIndicatorCircleProps {
  active?: boolean;
}

export const StepIndicatorCircle = ({ active }: StepIndicatorCircleProps) => {
  return (
    <Box
      w="8px"
      h="8px"
      borderRadius="50%"
      bg={active ? '#003366' : '#DBD9D7'}
    />
  );
};

const StepIndicator: React.FC<StepIndicatorItems> = ({
  length,
  activeStep,
  indicatorCaption,
}) => {
  return (
    <VStack>
      <HStack
        gap={{ base: '32px', lg: '52px' }}
        justifyContent="center"
        mb="24px"
      >
        {Array.apply(null, Array(length)).map((step, index: number) => {
          return (
            <StepIndicatorCircle
              key={`step-indicator-circle-${index}`}
              active={index === activeStep ? true : false}
            />
          );
        })}
      </HStack>
      <Overline>{`step ${
        activeStep + 1
      } of ${length}: ${indicatorCaption}`}</Overline>
    </VStack>
  );
};

export default StepIndicator;
