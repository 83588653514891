import { FormikErrors } from 'formik';

import {
  containsNumber,
  isDateLessThan,
  isNumber,
  isValidDateString,
  isValidEmail,
  isValidPhoneNumber,
  isValidSSN,
  isValidZipCode,
} from '../../../utils/validation.utils';
import { EnhancedClaimsFormProps } from './interface/EnhancedClaimsFormProps';

const ClaimsFormValidator = async (
  values: EnhancedClaimsFormProps,
): Promise<FormikErrors<EnhancedClaimsFormProps>> => {
  const errors: FormikErrors<EnhancedClaimsFormProps> = {};

  // generate current date
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const yyyy = tomorrow.getFullYear();
  let mm = tomorrow.getMonth() + 1; // Months start at 0!
  let dd = tomorrow.getDate();

  if (dd < 10) dd = 0 + dd;
  if (mm < 10) mm = 0 + mm;

  const formattedTomorrow = mm + '/' + dd + '/' + yyyy;

  // STEP 1

  if (!values.deceasedFirstName) errors.deceasedFirstName = 'Required';

  if (values.deceasedFirstName.length > 50)
    errors.deceasedFirstName = 'Please enter no more than 50 characters';

  if (containsNumber(values.deceasedFirstName))
    errors.deceasedFirstName = 'Please enter a valid first name';

  if (values.deceasedMiddleInitial) {
    if (containsNumber(values.deceasedMiddleInitial))
      errors.deceasedMiddleInitial = 'Please provide a valid initial';
    if (values.deceasedMiddleInitial.length > 1)
      errors.deceasedMiddleInitial = 'Please enter no more than 1 character';
  }

  if (!values.deceasedLastName) errors.deceasedLastName = 'Required';
  if (values.deceasedLastName.length > 50)
    errors.deceasedLastName = 'Please enter no more than 50 characters';
  if (containsNumber(values.deceasedLastName))
    errors.deceasedLastName = 'Please enter a valid last name';

  if (!values.deceasedDateOfBirth) errors.deceasedDateOfBirth = 'Required';
  if (
    values.deceasedDateOfBirth &&
    !isValidDateString(values.deceasedDateOfBirth)
  )
    errors.deceasedDateOfBirth = 'Please enter a date in MM/DD/YYYY format';

  if (
    values.deceasedDateOfDeath &&
    !isValidDateString(values.deceasedDateOfDeath)
  )
    errors.deceasedDateOfDeath = 'Please enter a date in MM/DD/YYYY format';

  if (isValidDateString(values.deceasedDateOfBirth)) {
    if (!isDateLessThan(values.deceasedDateOfBirth, formattedTomorrow))
      errors.deceasedDateOfBirth = 'Date cannot be in the future';
  }

  if (isValidDateString(values.deceasedDateOfDeath)) {
    if (!isDateLessThan(values.deceasedDateOfDeath, formattedTomorrow))
      errors.deceasedDateOfDeath = 'Date cannot be in the future';
  }
  if (
    isValidDateString(values.deceasedDateOfBirth) &&
    isValidDateString(values.deceasedDateOfDeath)
  ) {
    if (!isDateLessThan(values.deceasedDateOfBirth, values.deceasedDateOfDeath))
      errors.deceasedDateOfBirth =
        'Please enter a date of birth less than date of death';

    if (isDateLessThan(values.deceasedDateOfDeath, values.deceasedDateOfBirth))
      errors.deceasedDateOfDeath =
        'Please enter a date of death greater than date of birth';
  }

  if (
    values.deceasedSocialSecurity &&
    !isValidSSN(values.deceasedSocialSecurity)
  )
    errors.deceasedSocialSecurity =
      'Please provide a valid Social Security Number';

  if (!values.causeOfDeath) errors.causeOfDeath = 'Required';

  if (values.funeralHomeName && values.funeralHomeName.length > 150)
    errors.funeralHomeName = 'Please enter no more than 150 characters';

  if (values.funeralHomePhone && !isValidPhoneNumber(values.funeralHomePhone))
    errors.funeralHomePhone = 'Please provide a valid phone number';

  if (values.funeralHomeAddress1 && values.funeralHomeAddress1.length > 255)
    errors.funeralHomeAddress1 = 'Please enter no more than 255 characters';

  if (values.funeralHomeAddress2 && values.funeralHomeAddress2.length > 150)
    errors.funeralHomeAddress2 = 'Please enter no more than 150 characters';

  if (values.funeralHomeCity && values.funeralHomeCity.length > 255)
    errors.funeralHomeCity = 'Please enter no more than 255 characters';

  if (values.funeralHomeZipCode && !isValidZipCode(values.funeralHomeZipCode))
    errors.funeralHomeZipCode = 'Please provide a valid zip code';

  if (values.funeralHomeFax && !isValidPhoneNumber(values.funeralHomeFax))
    errors.funeralHomeFax = 'Please provide a valid fax number';

  // STEP 2

  if (!values.relationshipToDeceased)
    errors.relationshipToDeceased = 'Required';

  if (
    values.relationshipToDeceased === 'Spouse' ||
    values.relationshipToDeceased === 'Other' ||
    values.relationshipToDeceased === 'Child' ||
    values.relationshipToDeceased === 'Parent'
  ) {
    if (!values.claimantFirstName) errors.claimantFirstName = 'Required';
    if (values.claimantFirstName.length > 50)
      errors.claimantFirstName = 'Please enter no more than 50 characters';

    if (containsNumber(values.claimantFirstName))
      errors.claimantFirstName = 'Please enter a valid first name';

    if (values.claimantMiddleInitial) {
      if (containsNumber(values.claimantMiddleInitial))
        errors.claimantMiddleInitial = 'Please provide a valid initial';
      if (values.claimantMiddleInitial.length > 1)
        errors.claimantMiddleInitial = 'Please enter no more than 1 character';
    }

    if (!values.claimantLastName) errors.claimantLastName = 'Required';
    if (values.claimantLastName.length > 50)
      errors.claimantLastName = 'Please enter no more than 50 characters';

    if (containsNumber(values.claimantLastName))
      errors.claimantLastName = 'Please enter a valid first name';

    if (!values.claimantAddress1) errors.claimantAddress1 = 'Required';
    if (values.claimantAddress1 && values.claimantAddress1.length > 255)
      errors.claimantAddress1 = 'Please enter no more than 255 characters';
    if (values.claimantAddress2 && values.claimantAddress2.length > 255)
      errors.claimantAddress2 = 'Please enter no more than 255 characters';
    if (!values.claimantCity) errors.claimantCity = 'Required';
    if (values.claimantCity && values.claimantCity.length > 255)
      errors.claimantCity = 'Please enter no more than 255 characters';
    if (!values.claimantState) errors.claimantState = 'Required';
    if (!values.claimantZipCode) errors.claimantZipCode = 'Required';
    if (values.claimantZipCode && !isValidZipCode(values.claimantZipCode))
      errors.claimantZipCode = 'Please provide a valid zip code';

    if (!values.claimantPhone) errors.claimantPhone = 'Required';
    if (values.claimantPhone && !isValidPhoneNumber(values.claimantPhone))
      errors.claimantPhone = 'Please provide a valid phone number';

    if (!values.claimantEmail) errors.claimantEmail = 'Required';
    if (values.claimantEmail && !isValidEmail(values.claimantEmail))
      errors.claimantEmail = 'Please provide a valid email address';

    if (values.claimantEmail?.length > 150)
      errors.claimantEmail = 'Please enter no more than 150 characters';
  }

  if (values.relationshipToDeceased === 'WoodmenLife Representative') {
    if (!values.servicingRepSalesId) errors.servicingRepSalesId = 'Required';

    if (!values.deliverProceeds) errors.deliverProceeds = 'Required';

    if (!values.formsRecipient) errors.formsRecipient = 'Required';

    if (values.servicingRepSalesId) {
      if (values.servicingRepSalesId.length > 6)
        errors.servicingRepSalesId = 'Please enter no more than 6 characters';
      if (values.servicingRepSalesId.length < 6)
        errors.servicingRepSalesId = 'Please enter no less than 6 characters';
      if (!isNumber(values.servicingRepSalesId))
        errors.servicingRepSalesId = 'Please enter a valid sales id';
    }
  }

  // STEP 3

  if (!values.beneficiary) {
    errors.beneficiary = 'Required';
  }

  if (values.beneficiary === 'No') {
    if (!values.beneficiaryFirstName) errors.beneficiaryFirstName = 'Required';
    if (values.beneficiaryFirstName.length > 50)
      errors.beneficiaryFirstName = 'Please enter no more than 50 characters';

    if (containsNumber(values.beneficiaryFirstName))
      errors.beneficiaryFirstName = 'Please enter a valid first name';

    if (!values.beneficiaryLastName) errors.beneficiaryLastName = 'Required';
    if (values.beneficiaryLastName.length > 50)
      errors.beneficiaryLastName = 'Please enter no more than 50 characters';

    if (containsNumber(values.beneficiaryLastName))
      errors.beneficiaryLastName = 'Please enter a valid first name';

    if (values.beneficiaryMiddleInitial) {
      if (containsNumber(values.beneficiaryMiddleInitial))
        errors.beneficiaryMiddleInitial = 'Please provide a valid initial';

      if (values.beneficiaryMiddleInitial.length > 1)
        errors.beneficiaryMiddleInitial =
          'Please enter no more than 1 character';
    }

    if (values.beneficiaryAddress1.length > 255)
      errors.beneficiaryAddress1 = 'Please enter no more than 255 characters';
    if (values.beneficiaryAddress2.length > 255)
      errors.beneficiaryAddress2 = 'Please enter no more than 255 characters';
    if (values.beneficiaryCity.length > 255)
      errors.beneficiaryCity = 'Please enter no more than 255 characters';

    if (values.beneficiaryZipCode && !isValidZipCode(values.beneficiaryZipCode))
      errors.beneficiaryZipCode = 'Please provide a valid zip code';

    if (values.beneficiaryPhone && !isValidPhoneNumber(values.beneficiaryPhone))
      errors.beneficiaryPhone = 'Please provide a valid phone number';

    if (values.beneficiaryEmail && !isValidEmail(values.beneficiaryEmail))
      errors.beneficiaryEmail = 'Please provide a valid email address';

    if (values.beneficiaryEmail?.length > 150)
      errors.beneficiaryEmail = 'Please enter no more than 150 characters';
  }

  if (values.beneficiary === 'No' && values.beneficiaryDeceased === 'Yes') {
    if (
      values.beneficiaryDateOfDeath &&
      !isValidDateString(values.beneficiaryDateOfDeath)
    )
      errors.beneficiaryDateOfDeath =
        'Please enter a date in MM/DD/YYYY format';
  }

  // STEP 4

  if (values.additionalInformation && values.additionalInformation.length > 500)
    errors.additionalInformation = 'Please enter no more than 500 characters';

  return errors;
};

export default ClaimsFormValidator;
