export const inputMixpanelMap = new Map();
inputMixpanelMap.set('age', 'Age');
inputMixpanelMap.set('gender', 'Gender');
inputMixpanelMap.set('maritalStatus', 'Marital Status');
inputMixpanelMap.set('livingSituation', 'Home Ownership');
inputMixpanelMap.set('numberOfChildren', 'Number of Children');
inputMixpanelMap.set('state', 'State');
inputMixpanelMap.set('finalExpenses', 'Final Expenses');
inputMixpanelMap.set('debt', 'Debt');
inputMixpanelMap.set('mortgage', 'Mortgage');
inputMixpanelMap.set('educationExpenses', 'Education Expenses');
inputMixpanelMap.set('yearsNeeded', 'Number Of Years Needed');
inputMixpanelMap.set('monthlyLivingExpenses', 'Monthly Living Expenses');
inputMixpanelMap.set('currentAssets', 'Current Assets');
inputMixpanelMap.set('child-1', 'Child 1');
inputMixpanelMap.set('child-2', 'Child 2');
inputMixpanelMap.set('child-3', 'Child 3');
inputMixpanelMap.set('child-4', 'Child 4');
inputMixpanelMap.set('child-5', 'Child 5');
inputMixpanelMap.set('child-6', 'Child 6');
inputMixpanelMap.set('child-7', 'Child 7');
inputMixpanelMap.set('child-8', 'Child 8');
inputMixpanelMap.set('child-9', 'Child 9');
inputMixpanelMap.set('child-10', 'Child 10');
inputMixpanelMap.set('debtAutoLoans', 'Itemization Auto Loans');
inputMixpanelMap.set('debtPersonalLoans', 'Itemization Personal Loans');
inputMixpanelMap.set('debtCreditCards', 'Itemization Credit Cards');
inputMixpanelMap.set('debtOther', 'Itemization Debt Other');
inputMixpanelMap.set(
  'currentAssetsLifeInsurance',
  'Itemization Life Insurance',
);
inputMixpanelMap.set('currentAssetsRetirement', 'Itemization Retirement');
inputMixpanelMap.set(
  'currentAssetsSavingsChecking',
  'Itemization Savings, Checking',
);
inputMixpanelMap.set('currentAssetsStocksBonds', 'Itemization Stocks, Bonds');
inputMixpanelMap.set('currentAssetsAnnuities', 'Annuities');
inputMixpanelMap.set('currentAssetsOther', 'Itemization Current Assets Other');
inputMixpanelMap.set('firstName', 'First Name');
inputMixpanelMap.set('lastName', 'Last Name');
inputMixpanelMap.set('zipCode', 'ZIP Code');
inputMixpanelMap.set('phone', 'Phone Number');
inputMixpanelMap.set('email', 'Email Address');
