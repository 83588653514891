import { inputMixpanelMap } from '../components/LifeInsuranceCalculatorV2/utils/inputMap';
import utils from '../components/LifeInsuranceCalculatorV2/utils/utils';
export function trackMixpanel(
  event: any,
  stepName: string,
  type: string,
  mixpanel: any,
) {
  let { name, value } = event.target;
  const noTrack = ['firstName', 'lastName', 'zipCode', 'phone', 'email'];
  name = inputMixpanelMap.get(name);

  if (type === 'blur') {
    if (name === inputMixpanelMap.get('age')) {
      if (!utils.isEmpty(value) && utils.isNumeric(value)) {
        mixpanel.track(`${stepName} - ${name} Input`, {
          Trigger: 'blur',
          Input: value,
        });
      }
    } else if (noTrack.includes(event.target.name)) {
      mixpanel.track(`${stepName} - ${name} Input`, {
        Trigger: 'blur',
      });
    } else if (!utils.isEmpty(value)) {
      mixpanel.track(`${stepName} - ${name} Input`, {
        Trigger: 'blur',
        Input: value,
      });
    }
  }

  if (type === 'focus') {
    if (name === inputMixpanelMap.get('age')) {
      if (!utils.isEmpty(value) && utils.isNumeric(value)) {
        mixpanel.track(`${stepName} - ${name} Input`, {
          Trigger: 'focus',
          Input: value,
        });
      }
    } else if (noTrack.includes(event.target.name)) {
      mixpanel.track(`${stepName} - ${name} Input`, {
        Trigger: 'focus',
      });
    } else if (!utils.isEmpty(value)) {
      mixpanel.track(`${stepName} - ${name} Input`, {
        Trigger: 'focus',
        Input: value,
      });
    }
  }

  if (type === 'start') {
    const ts = Math.floor(Date.now() / 1000);
    mixpanel.track(`${stepName} - Start`, {
      'Start Time': ts,
    });
  }

  if (type === 'end') {
    const ts = Math.floor(Date.now() / 1000);
    mixpanel.track(`${stepName} - End`, {
      'End Time': ts,
    });
  }
}

export function trackStepStartMixpanel(stepName: string, mixpanel: any) {
  const ts = Math.floor(Date.now() / 1000);
  mixpanel.track(`${stepName} - Start`, {
    'Start Time': ts,
  });
}

export function trackStepEndMixpanel(stepName: string, mixpanel: any) {
  const ts = Math.floor(Date.now() / 1000);
  mixpanel.track(`${stepName} - End`, {
    'End Time': ts,
  });
}

export function trackItemizationStartMixpanel(stepName: string, mixpanel: any) {
  const ts = Math.floor(Date.now() / 1000);
  mixpanel.track(`${stepName} -  Itemization Start`, {
    'Start Time': ts,
  });
}

export function trackItemizationEndMixpanel(stepName: string, mixpanel: any) {
  const ts = Math.floor(Date.now() / 1000);
  mixpanel.track(`${stepName} - Itemization End`, {
    'End Time': ts,
  });
}

export function trackItemizationUpdateMixpanel(
  stepName: string,
  mixpanel: any,
) {
  mixpanel.track(`${stepName} - Itemization Update`, {
    Clicked: true,
  });
}
