import { Box } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import React from 'react';

import ClaimsForm from '../../components/Claims/ClaimsForm';
import Layout from '../../components/Layout';
import { WLContainer } from '../../design-library';
interface ClaimsPageProps extends PageProps<undefined> { }

const ClaimsPage: React.FC<ClaimsPageProps> = ({ location }) => {
  return (
    <Layout
      seo={{
        title: 'File a Life Insurance Claim',
        description: 'We\'re here for you in this difficult time. Let us help you through filing a WoodmenLife life insurance claim.',
      }}
      currentPath={location.pathname}
    >
      <WLContainer position="relative">
        <Box
          w="full"
          h={{ base: '104px', md: '150px' }}
          position="absolute"
          top={0}
          zIndex={'-1'}
          bgGradient="linear(to-b, rgba(181, 204, 225, .25), white)"
        />
        <ClaimsForm />
      </WLContainer>
    </Layout>
  );
};

export default ClaimsPage;
