import {
  Radio,
  RadioGroup,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { FieldInputProps, useFormikContext } from 'formik';
import React from 'react';

import {
  WLCheckbox,
  WLInput,
  WLSelect,
  WLTextarea,
} from '../../../../design-library';
import {
  FormFieldVariant,
  HelperTextPosition,
} from '../../../../design-library/Forms/FormField/FormField';
import { SelectVariant } from '../../../../design-library/Forms/Select/Select';
import FormikFieldAdapter, {
  PhoneInputField,
  SocialSecurityInputField,
} from '../../../Form';
import { InputProps } from '../FormStep/interface/FormStepProps';
import { EnhancedClaimsFormProps } from '../interface/EnhancedClaimsFormProps';
interface InputGeneratorProps {
  input: InputProps;
}

interface RadioProps extends FieldInputProps<'any'> {
  options: string[] | undefined;
  name: EnhancedClaimsFormProps[
    | 'naturalCauseOfDeathOptions'
    | 'formsRecipient'
    | 'deliverProceeds'
    | 'beneficiary'
    | 'beneficiaryDeceased'];
}

export const ClaimsRadioGroup: React.FC<RadioProps> = ({
  options,
  name,
  onChange,
}) => {
  const { values } = useFormikContext<EnhancedClaimsFormProps>();

  const styles = useMultiStyleConfig('Radio', {});
  const handleChange = React.useCallback(
    (value: string) => {
      onChange?.({ target: { name, value } } as any);
    },
    [name, onChange],
  );
  if (!options) return null;

  return (
    <RadioGroup
      onChange={handleChange}
      name={name}
      defaultValue=""
      value={values[name] as RadioProps['name']}
    >
      <VStack align={'flex-start'} paddingX={4}>
        {options?.map((option: any) => {
          return (
            <Radio
              value={option.value || option}
              color={'red'}
              __css={styles.control}
            >
              {option.label || option}
            </Radio>
          );
        })}
      </VStack>
    </RadioGroup>
  );
};

const InputGenerator: React.FC<InputGeneratorProps> = ({ input }) => {
  const { values } = useFormikContext<EnhancedClaimsFormProps>();

  const optionsGenerator = React.useMemo(
    () =>
      input.options &&
      input.type !== 'radio' &&
      Object.entries(input.options).map(([abbrev, name]) => ({
        label: `${name}`,
        value: abbrev,
      })),
    [input.options, input.type],
  );

  return (
    <>
      <FormikFieldAdapter
        key={`input-${input.name}`}
        name={input.name}
        label={input.type !== 'checkbox' ? input.label : undefined}
        required={input.required}
        showRequiredLabel={false}
        hideLabel={input.hideLabel}
        variant={FormFieldVariant.claims}
        helperText={input.placeholder || ''}
        helperTextPosition={HelperTextPosition.bottom}
        renderField={(adapterProps) => (
          <>
            {input.type === 'text' && (
              <WLInput
                {...adapterProps}
                bg="white"
                maxLength={input.maxLength}
                autoFocus={false}
                type={input.type}
                borderRadius="8px"
                border="1px"
                borderColor="#DBD9D7"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="normal"
                color="#797877"
                autoComplete={input.autoComplete || 'true'}
              />
            )}
            {input.type === 'phone' && (
              <PhoneInputField
                {...adapterProps}
                name={input.name}
                label={input.label}
                autoComplete="false"
                maxLength={input.maxLength}
                width={input.width || '100%'}
                required={input.required}
                bg="white"
                borderRadius="8px"
                border="1px"
                borderColor="#DBD9D7"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="normal"
                color="#797877"
              />
            )}
            {input.type === 'ssn' && (
              <SocialSecurityInputField
                {...adapterProps}
                name={input.name}
                label={input.label}
                maxLength={input.maxLength}
                width={input.width || '100%'}
                required={input.required}
                bg="white"
                borderRadius="8px"
                border="1px"
                borderColor="#DBD9D7"
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="normal"
                color="#797877"
              />
            )}
            {input.type === 'select' && (
              <WLSelect
                {...adapterProps}
                variant={SelectVariant.OUTLINE_WHITE}
                options={optionsGenerator || []}
              />
            )}
            {input.type === 'radio' && (
              <ClaimsRadioGroup
                {...adapterProps}
                options={input.radioOptions || []}
              />
            )}
            {input.type === 'checkbox' && (
              <WLCheckbox
                variant="claims"
                {...adapterProps}
                isChecked={values[input.name] === true ? true : false}
              >
                {input.label}
              </WLCheckbox>
            )}
            {input.type === 'textarea' && (
              <>
                <WLTextarea
                  {...adapterProps}
                  borderRadius="8px"
                  border="1px"
                  borderColor="#DBD9D7"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="normal"
                  color="#797877"
                  bg="white"
                  maxLength={input.maxLength}
                />
              </>
            )}
          </>
        )}
      />
    </>
  );
};

export default InputGenerator;
