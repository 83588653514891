import React from 'react';

import { formHeaderRichText } from '../FormStepperHeader';

const handleBold = (text: string): (React.ReactNode | string)[] => {
  const array = text.split('*');

  const enhancedParagraphArrayBold = array
    .map((element, idx) => {
      if (idx % 2 !== 0) {
        const newElement = <b>{element}</b>;
        return newElement;
      } else {
        return element;
      }
    })
    .filter((element) => element !== '');
  return enhancedParagraphArrayBold;
};
const handleItalic = (text: string): (React.ReactNode | string)[] => {
  const array = text.split('**');
  const enhancedParagraphArrayItalic = array

    .map((element, idx) => {
      if (idx % 2 !== 0) {
        const newElement = <i>{element}</i>;
        return newElement;
      } else {
        return element;
      }
    })
    .filter((element) => element !== '');

  return enhancedParagraphArrayItalic;
};

const handleText = (text: string): string[] => {
  return new Array(text);
};

export const getRenderers = (para: {
  text: string;
  richText: formHeaderRichText;
}): (React.ReactNode | string)[] => {
  let enhancedParagraphArray: (React.ReactNode | string)[] =
    para.richText === formHeaderRichText.none
      ? handleText(para.text)
      : para.richText === formHeaderRichText.bold
      ? handleBold(para.text)
      : handleItalic(para.text);
  return enhancedParagraphArray;
};
